<template>
  <div class="Merchant-footer">
    <div class="Merchant">
      <p style="padding:10px 0 0 6px" @click="$router.go(-1)">
        <van-icon name="arrow-left" size="24px" />
      </p>
      <p style="font-size:17px;font-weight: bold; padding:10px 0 0 0">商家管理中心</p>
      <p style="padding:10px 14px 0 0" @click="getMechanttype">
        <van-icon name="replay" size="24px" />
      </p>
    </div>
    <div class="Merchant-header">
      <div class="Merchant-center">
        <p style="font-size:18px;color:#FFE5C8;padding:14px 0 0 12px">总粉丝数</p>
        <p style="color:#FFE5C8;font-size:24px;padding:12px 0 0 12px">
          {{fansTotal}}
          <i style="font-size:12px;padding:8px 0 0 8px">人</i>
        </p>
        <p class="Merchant-person"></p>
        <div class="Merchant-streaming">
          <div class="Merchant-streaming-peron">
            <p style="margin-top:12px">{{straightTotal}}</p>
            <p style="margin:9px 0 5px 0" @click="merchanter(1)">直邀粉丝(人)</p>
          </div>
          <div class="person-line"></div>
          <div class="Merchant-streaming-peron">
            <p style="margin-top:12px">{{recommendTotal}}</p>
            <p style="margin:9px 0 5px 0" @click="merchanter(2)" >推荐粉丝(人)</p>
          </div>
          <div class="person-line"></div>
          <div class="Merchant-streaming-peron">
            <p style="margin-top:12px">{{potentialTotal}}</p>
            <p style="margin:9px 0 5px 0" @click="merchanter(3)">潜在粉丝(人)</p>
          </div>
        </div>
      </div>
      <div class="Merchant-figure">活跃情况</div>
      <div class="Merchant-foot">
        <van-grid :column-num="3">
          <van-grid-item v-for="(item,index) in Merchantlist" :key="index">
            <p class="Merchant-foot-one">
              <span>{{item.value}}</span>
              <span>{{item.title}}</span>
            </p>
          </van-grid-item>
          
        </van-grid>
        
      </div>
      <div class="Merchant-footcall">

      </div>
    </div>
  </div>
</template>

<script>
import { getMechant } from '@/api/Merchant/Merchant'
import { mapState } from 'vuex';
export default {
  name: "Merchant",
  data() {
    return {
     Merchantlist:[],
     fansTotal:'',
     straightTotal:'',
     recommendTotal:'',
     potentialTotal:'',
     currentIndex:1,
    //  type:1
    };
  },
   computed: {
    ...mapState(["session"]),
  },
  created(){
 this.getMechanttype()
  },
   mounted() {
    //定义全局session
    if (this.$route.query.session != null) {
      // this.session = this.$route.query.session;
      //设置session
      this.$store.commit("setSession", this.$route.query.session);
    }
  },
  methods:{
    merchanter(n){
      this.$router.push({path:'fansManagementNew',query:{currentIndex:n,'type':1}})
    },
   getMechanttype(){
     let params = {}
     getMechant(params).then(res =>{
       this.fansTotal = res.data.data.fansTotal,
       this.straightTotal = res.data.data.straightTotal,
       this.recommendTotal = res.data.data.recommendTotal,
       this.potentialTotal = res.data.data.potentialTotal,
      this.Merchantlist = [
        {title:'历史下单',value:res.data.data.totalBuy},
        {title:'今日活跃人数',value:res.data.data.activeFans},
        {title:'今日首购',value:res.data.data.todayFirstBuy},
        {title:'今天取关人数',value:res.data.data.todayNoFans},
        {title:'今日新增人数',value:res.data.data.todeyFans},
        {title:'今日下单',value:res.data.data.todayBuy}
      ]
     })
   },
   refresh(){
     console.log(123);
    //  location.reload()
    this.getMechanttype()
    
   }
  }
};
</script>

<style lang="less" scoped>

.Merchant {
  height: 44px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  justify-content: space-between;
  z-index: 99999;
}
.Merchant-header{
  // width: 100%;
 
  // background-color: rgba(204, 204, 204, 1);
  padding: 50px 16px 0 16px;
}
.Merchant-center {

  
  // height: 100%;
  // display: block;
  // height: 215px;
 
  background:url('../../assets/pic.png') no-repeat center center;
   background-size: 100%;
  border-radius: 5px;
}
.Merchant-person {
  /* width:323px; */
  height: 1px;
  border: 0px solid #cccccc;
  
  margin: 10px 12px 10px 12px;
  background-color: #cccccc;
}
.Merchant-streaming {
  display: flex;
  color: #ffe5c8;
  justify-content: space-around;
}
.Merchant-streaming-peron {
  /* width: 75px; */
  height: 56px;
  font-size: 12px;
  text-align: center;
}
.person-line {
  /* width: 1px; */
  height: 36px;
  border: 1px solid #cccccc;
  margin-top: 13px;
}
.Merchant-figure {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  padding: 15px 0 10px 8px;
}

.Merchant-foot {
  border-radius: 5px;
  box-shadow: #666 0 2px 2px 0;
  display: block;
  .Merchant-foot-one{
    display: flex; 
    flex-direction: column;
    text-align: center;
    :first-child{
      font-weight: bold;
    }
    :last-child{
      font-size: 10px;
      color: #666;
    }
  }
  // .Merchant-footer {
  //   display: flex;
  //   width: 33%;
  //   // justify-content: space-between;
  //   .Merchant-footer-two {
  //     border: 1px solid red;
  //     height: 50px;
  //     font-size: 12px;
  //     :first-child{
  //       display: block;
  //       text-align: center;
  //       // padding-top: 14px;
  //     }
  //     :last-child{
  //       display: block;
  //       padding-top: 9px;
  //     }
  //   }
  // }
}
.Merchant-footcall{
  height: 334px;
  // background-color: #CCCCCC;
}
</style>
